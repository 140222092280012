import { addPropertyControls, ControlType, Link } from "framer"
import { useEffect, useState, useRef } from "react"
import { motion } from "framer-motion"
import {
    FormIcon,
    iconPropertyControls,
    useFormStore,
    SUPABASE_KEY,
    fillProp,
    createBackground,
} from "https://framer.com/m/SharedFormCode-HQOZ.js@O0p4MzfmiDhQrYFnFReg"

/**
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any
 * @framerIntrinsicWidth 500
 */
export default function SubmitButton(props) {
    const {
        formId,
        clickAction,
        submitPlatform,
        submitUrl,
        responseInfo,
        redirectOnSuccess,
        scrollOptions,
        icon,
        border,
        success,
        failure,
        apiConfig,
    } = props
    const borderRadius = props.radiusIsMixed
        ? `${props.radiusTopLeft}px ${props.radiusTopRight}px ${props.radiusBottomRight}px ${props.radiusBottomLeft}px`
        : `${props.radius}px`

    const [formState, setFormState] = useFormStore()
    const [submitted, setSubmitted] = useState(false)
    const redirectLinkRef = useRef(null)

    // Reset/clear form
    function resetFormState() {
        setFormState((prev) => {
            const newForm = {}
            for (const fieldName in prev[formId]) {
                newForm[fieldName] = {
                    ...prev[formId][fieldName],
                    value: prev[formId][fieldName].defaultValue,
                }
            }
            return { [formId]: newForm }
        })
    }

    useEffect(() => {
        setFormState({})

        return () => {
            setFormState((prev) => {
                const { [formId]: _, ...newState } = prev
                return newState
            })
        }
    }, [])

    async function onSubmitClick() {
        const invalidFields = []

        if (submitted) {
            return
        }

        // Find invalid fields
        const form = formState[formId]
        for (const fieldName in form) {
            const field = form[fieldName]

            if (field.visible && !(await field.isValid(field.value))) {
                invalidFields.push(fieldName)
            }
        }

        if (invalidFields.length == 0) {
            if (clickAction == "submitForm") {
                // Process final field values
                const formData = {}
                for (const fieldName in form) {
                    const field = form[fieldName]
                    const name = processFieldName(fieldName, submitPlatform)

                    if (field.processValue) {
                        formData[name] = await field.processValue(field.value)
                    } else {
                        formData[name] = field.value
                    }
                }

                let url = ""
                let fetchMode = "no-cors"
                let contentType = "application/json"
                let headers = {}

                switch (submitPlatform) {
                    case "url":
                        url = submitUrl.replace(
                            "submit-form.com",
                            "api.formspark.io"
                        )

                        if (url.includes("loops.so/api")) {
                            contentType = "application/x-www-form-urlencoded"
                        }

                        // Fix CORS issues with Formspark and Make.com
                        if (
                            url.includes("submit-form.com") ||
                            url.includes("api.formspark.io") ||
                            url.includes(".make.com")
                        ) {
                            fetchMode = "cors"
                        }

                        if (apiConfig?.apiKey?.length) {
                            headers.Authorization = `Bearer ${apiConfig.apiKey}`
                        }
                        break
                    case "zapier":
                        url = props.zapierWebhookUrl
                        break
                    case "make":
                        url = props.makeWebhookUrl
                        fetchMode = "cors"
                        break
                    case "formspark":
                        url = `https://api.formspark.io/${props.formsparkFormId}`
                        fetchMode = "cors"
                        break
                    case "formspree":
                        url = props.formspreeEndpoint
                        break
                    case "loops":
                        url = props.loopsUrl
                        contentType = "application/x-www-form-urlencoded"
                        fetchMode = "cors"
                        if (props.loopsUserGroup?.length) {
                            formData.userGroup = props.loopsUserGroup
                        }

                        // Email address
                        for (const fieldName in form) {
                            const field = form[fieldName]
                            if (field.type == "email") {
                                delete formData[field.name]
                                formData.email = field.value
                                break
                            }
                        }
                        break
                    case "mailchimp":
                        const [domain, parameters] = parseMailchimpUrl(
                            props.mailchimpUrl
                        )

                        url = `https://${domain}/subscribe/post`
                        contentType =
                            "application/x-www-form-urlencoded;charset=UTF-8"

                        if (parameters) {
                            for (const key in parameters) {
                                formData[key] = parameters[key]
                            }
                        }

                        // Find email, phone, and birthday fields
                        let emailFound = false
                        let phoneFound = false
                        let birthdayFound = false
                        for (const fieldName in form) {
                            const field = form[fieldName]

                            if (!emailFound && field.type == "email") {
                                delete formData[field.name]
                                formData.EMAIL = field.value
                                emailFound = true
                            }

                            if (!phoneFound && field.type == "phone") {
                                delete formData[field.name]
                                formData.PHONE = field.value
                                phoneFound = true
                            }

                            if (
                                !birthdayFound &&
                                field.type == "date" &&
                                field.name == "birthday"
                            ) {
                                formData["BIRTHDAY[month]"] =
                                    field.value.getMonth() + 1
                                formData["BIRTHDAY[day]"] =
                                    field.value.getDate()
                                delete formData[field.name]
                                birthdayFound = true
                            }
                        }

                        break
                    case "mailerLite":
                        url = props.mailerLiteUrl
                        contentType = "application/x-www-form-urlencoded"

                        // Email address
                        for (const fieldName in form) {
                            const field = form[fieldName]
                            if (field.type == "email") {
                                delete formData[field.name]
                                formData["fields[email]"] = field.value
                                break
                            }
                        }
                        break
                    case "hubSpot":
                        url = `https://api.hsforms.com/submissions/v3/integration/submit/${props.hubSpotPortalId}/${props.hubSpotFormId}`
                        fetchMode = "cors"
                        break
                    case "klaviyo":
                        url =
                            "https://manage.kmail-lists.com/subscriptions/external/subscribe"
                        contentType = "application/x-www-form-urlencoded"

                        formData.g = props.klaviyoListId // g is the list ID field

                        // Email address
                        for (const fieldName in form) {
                            const field = form[fieldName]
                            if (field.type == "email") {
                                delete formData[field.name]
                                formData["$email"] = field.value
                                break
                            }
                        }

                        break
                }

                // Add URL to form response
                if (responseInfo.url) {
                    formData[responseInfo.urlName] = window.location.href
                }

                // Add UTM parameters
                if (responseInfo.utmParams) {
                    const queryParams = new URLSearchParams(
                        window.location.search
                    )

                    queryParams.forEach((value, key) => {
                        // Check if the key starts with 'utm_' (indicating it's a UTM parameter)
                        if (key.startsWith("utm_")) {
                            formData[key] = value
                        }
                    })
                }

                let body = ""
                // if (submitPlatform == "hubSpot") {
                //     const hsBody = {
                //         context: {
                //             pageUri: window.location.href,
                //             pageName: document.title,
                //         },
                //         fields: Object.keys(form).map((fieldName) => {
                //             const field = form[fieldName]

                //             return {
                //                 objectTypeId: "0-1",
                //                 name: fieldName,
                //                 value: field.value,
                //             }
                //         }),
                //     }

                //     // Add HutSpot tracking cookie.
                //     // Requires the HubSpot tracking script to be installed on the page.
                //     const hutk = getCookieValue("hubspotutk")
                //     if (hutk?.length) {
                //         hsBody.context.hutk = hutk
                //     }

                //     let communicationsText = ""
                //     let processingText = ""
                //     for (const fieldName in form) {
                //         if (fieldName == "communications") {
                //             communicationsText =
                //                 form[fieldName].ref.current?.textContent
                //         } else if (fieldName == "processing") {
                //             processingText =
                //                 form[fieldName].ref.current?.textContent
                //         }
                //     }

                //     switch (props.hubSpotConsentType) {
                //         case "communications":
                //         case "communicationsAndProcessing":
                //             hsBody.legalConsentOptions = {
                //                 consent: {
                //                     consentToProcess: true,
                //                     text: processingText,
                //                     communications: [
                //                         {
                //                             value: false,
                //                             subscriptionTypeId:
                //                                 props.hubSpotSubscriptionTypeId,
                //                             text: communicationsText,
                //                         },
                //                     ],
                //                 },
                //             }
                //             break
                //         case "legitimateInterest":
                //             hsBody.legalConsentOptions = {
                //                 legitimateInterest: {
                //                     value: true, // Finish this //
                //                     subscriptionTypeId:
                //                         props.hubSpotSubscriptionTypeId,
                //                     legalBasis: props.hubSpotLegalBasis,
                //                     text: "",
                //                 },
                //             }
                //             break
                //     }

                //     for (const fieldName in form) {
                //         const field = form[fieldName]
                //         if (
                //             field.type == "consent" ||
                //             field.type == "checkbox"
                //         ) {
                //             hsBody.legalConsentOptions = {
                //                 consent: {
                //                     consentToProcess: true,
                //                     text: field.ref.current?.textContent,
                //                     communications: [
                //                         {
                //                             value: true,
                //                             subscriptionTypeId:
                //                                 props.hubSpotSubscriptionTypeId,
                //                         },
                //                     ],
                //                 },
                //             }
                //             delete formData[field.name]
                //             break
                //         }
                //     }

                //     body = JSON.stringify(hsBody)
                // } else {
                if (contentType == "application/json") {
                    body = JSON.stringify(formData)
                } else if (
                    contentType.startsWith("application/x-www-form-urlencoded")
                ) {
                    body = new URLSearchParams(formData).toString()
                }
                // }

                setSubmitted(true)

                try {
                    const response = await fetch(url, {
                        method: "POST",
                        mode: fetchMode,
                        body,
                        headers: {
                            "Content-Type": contentType,
                            Accept: "application/json",
                            ...headers,
                        },
                    })

                    if (response.ok || response.type == "opaque") {
                        if (
                            redirectOnSuccess?.length &&
                            redirectLinkRef.current
                        ) {
                            redirectLinkRef.current.click()
                        }

                        try {
                            fetch(
                                "https://mhrwdvsitrzczrneuaxg.supabase.co/rest/v1/framerforms_websites",
                                {
                                    method: "POST",
                                    headers: {
                                        apikey: SUPABASE_KEY,
                                        Authorization: `Bearer ${SUPABASE_KEY}`,
                                        "Content-Type": "application/json",
                                        Prefer: "return=minimal",
                                    },
                                    body: JSON.stringify({
                                        url: window.location.href,
                                    }),
                                }
                            )
                        } catch (error) {
                            console.error("Error:", error)
                        }

                        resetFormState()
                        setSubmitted(false)
                        success?.()
                    } else {
                        setSubmitted(false)
                        failure?.()
                    }
                } catch (error) {
                    console.error("Error submitting form: ", error)
                    setSubmitted(false)
                    failure?.()
                }
            } else {
                // Validate form without submitting to URL
                setSubmitted(false)
                success?.()
            }
        } else {
            console.log(
                "Not all form fields are valid:",
                invalidFields.join(", ")
            )

            if (scrollOptions) {
                // Scroll to first invalid field
                let closestRef = null
                let closestDistance = Number.POSITIVE_INFINITY

                for (const fieldName in form) {
                    const ref = form[fieldName].ref

                    if (invalidFields.includes(fieldName) && ref.current) {
                        const rect = ref.current.getBoundingClientRect()
                        const distance = rect.top + window.pageYOffset
                        if (distance >= 0 && distance < closestDistance) {
                            closestRef = ref
                            closestDistance = distance
                        }
                    }
                }

                if (closestRef) {
                    window.scrollTo({
                        behavior: "smooth",
                        top:
                            closestRef.current.getBoundingClientRect().top -
                            document.body.getBoundingClientRect().top +
                            scrollOptions.offset,
                    })
                }
            }

            setSubmitted(false)
            failure?.()
        }
    }

    return (
        <motion.button
            type="submit"
            onClick={onSubmitClick}
            whileHover={{
                ...createBackground(props.hoverCustomization?.fill),
                color: props.hoverCustomization?.fontColor,
                "--border-color": props.hoverCustomization?.borderColor,
            }}
            whileTap={{
                ...createBackground(props.pressCustomization?.fill),
                color: props.pressCustomization?.fontColor,
                "--border-color": props.pressCustomization?.borderColor,
            }}
            animate={{
                borderRadius: borderRadius,
                padding: props.paddingIsMixed
                    ? `${props.paddingTop}px ${props.paddingRight}px ${props.paddingBottom}px ${props.paddingLeft}px`
                    : `${props.padding}px`,
                ...createBackground(props.fill),
                boxShadow: props.shadows,
                color: props.fontColor,
                "--border-color": border?.color,
            }}
            style={{
                position: "relative",
                display: "flex",
                flexDirection:
                    icon?.position == "right" ? "row-reverse" : "row",
                gap: icon?.gap,
                alignItems: "center",
                justifyContent: props.font?.textAlign || "center",
                border: "none",
                outline: "none",
                cursor: "pointer",
                userSelect: "none",
                ...props.font,
                ...props.style,
            }}
            initial={false}
            transition={props.transition}
        >
            <FormIcon icon={icon} style={{}} />
            {props.text}
            {border && (
                <motion.div
                    animate={{
                        borderRadius: borderRadius,
                    }}
                    style={{
                        position: "absolute",
                        inset: 0,
                        borderWidth: border.widthIsMixed
                            ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                            : `${border.width}px`,
                        borderStyle: border.style,
                        borderColor: "var(--border-color)",
                        pointerEvents: "none",
                    }}
                    initial={false}
                    transition={props.transition}
                />
            )}
            {redirectOnSuccess && (
                <Link
                    ref={redirectLinkRef}
                    href={redirectOnSuccess}
                    openInNewTab={props.newTab}
                    smoothScroll={false}
                >
                    <a />
                </Link>
            )}
        </motion.button>
    )
}

SubmitButton.displayName = "Submit Button"

addPropertyControls(SubmitButton, {
    formId: {
        type: ControlType.Number,
        defaultValue: 0,
        step: 1,
        min: 0,
        displayStepper: true,
        title: "Form ID",
    },
    clickAction: {
        type: ControlType.Enum,
        defaultValue: "submitForm",
        options: ["submitForm", "validateForm"],
        optionTitles: ["Submit Form", "Validate Form"],
    },
    ///////////////////////////////////////////////////////////////////////
    submitPlatform: {
        type: ControlType.Enum,
        defaultValue: "url",
        options: [
            "url",
            // "airtable",
            "formspark",
            "formspree",
            // "hevoData",
            // "hubSpot",
            // "klaviyo",
            "loops",
            "mailchimp",
            "mailerLite",
            "make",
            // "n8n",
            "zapier",
        ],
        optionTitles: [
            "Custom URL",
            // "Airtable",
            "Formspark",
            "Formspree",
            // "Hevo Data",
            // "HubSpot",
            // "Klaviyo",
            "Loops",
            "Mailchimp",
            "MailerLite",
            "Make.com",
            // "n8n",
            "Zapier",
        ],
        title: "Submit To",
        hidden: notSubmitForm,
    },
    submitUrl: {
        title: "URL",
        type: ControlType.String,
        defaultValue: "",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "url",
    },
    zapierWebhookUrl: {
        title: "Webhook URL",
        type: ControlType.String,
        defaultValue: "",
        placeholder: "https://hooks.zapier.com/hooks/catch/...",
        description: "Webhook URL from the Webhooks by Zapier action",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "zapier",
    },
    makeWebhookUrl: {
        title: "Webhook URL",
        type: ControlType.String,
        defaultValue: "",
        placeholder: "https://hook.us1.make.com/...",
        description: "Webhook URL from the custom webhook bubble",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "make",
    },
    formsparkFormId: {
        title: "Formspark Form ID",
        type: ControlType.String,
        defaultValue: "",
        description: "Form ID from Formspark",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "formspark",
    },
    formspreeEndpoint: {
        title: "Formspree Endpoint",
        type: ControlType.String,
        defaultValue: "",
        placeholder: "https://formspree.io/f/...",
        description: "Form endpoint URL from Formspree",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "formspree",
    },
    loopsUrl: {
        title: "Loops URL",
        type: ControlType.String,
        defaultValue: "",
        placeholder: "https://app.loops.so/api/newsletter-form/...",
        description: "Form endpoint URL from Loops",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "loops",
    },
    loopsUserGroup: {
        title: "User Group",
        type: ControlType.String,
        defaultValue: "",
        placeholder: "Loops User Group",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "loops",
    },
    mailchimpUrl: {
        title: "Mailchimp URL",
        type: ControlType.String,
        defaultValue: "",
        placeholder: "https://***.us*.list-manage.com/subscribe/post?u=...",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "mailchimp",
    },
    mailerLiteUrl: {
        title: "MailerLite URL",
        type: ControlType.String,
        defaultValue: "",
        placeholder:
            "https://assets.mailerlite.com/jsonp/.../forms/.../subscribe",
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "mailerLite",
    },
    // hubSpotPortalId: {
    //     type: ControlType.String,
    //     defaultValue: "",
    //     title: "Portal ID",
    //     placeholder: "HubSpot Portal ID",
    //     hidden: (props) =>
    //         notSubmitForm(props) || props.submitPlatform != "hubSpot",
    // },
    // hubSpotFormId: {
    //     type: ControlType.String,
    //     defaultValue: "",
    //     title: "Form ID",
    //     placeholder: "HubSpot Form ID",
    //     hidden: (props) =>
    //         notSubmitForm(props) || props.submitPlatform != "hubSpot",
    // },
    // hubSpotSubscriptionTypeId: {
    //     type: ControlType.Number,
    //     title: "Subscription Type ID",
    //     displayStepper: true,
    //     hidden: (props) =>
    //         notSubmitForm(props) || props.submitPlatform != "hubSpot",
    // },
    // hubSpotConsentType: {
    //     type: ControlType.Enum,
    //     defaultValue: "communications",
    //     options: [
    //         "communications",
    //         "communicationsAndProcessing",
    //         "legitimateInterest",
    //     ],
    //     optionTitles: [
    //         "Consent checkbox for communications; form submit as consent to process",
    //         "Consent checkboxes for communications and processing",
    //         "Legitimate interest",
    //     ],
    //     title: "Consent Type",
    //     hidden: (props) =>
    //         notSubmitForm(props) || props.submitPlatform != "hubSpot",
    // },
    // hubSpotLegalBasis: {
    //     type: ControlType.Enum,
    //     defaultValue: "customer",
    //     options: ["CUSTOMER", "LEAD"],
    //     optionTitles: ["Customer", "Lead"],
    //     displaySegmentedControl: true,
    //     title: "Legal Basis",
    //     hidden: (props) =>
    //         notSubmitForm(props) ||
    //         props.submitPlatform != "hubSpot" ||
    //         props.hubSpotConsentType != "legitimateInterest",
    // },
    // klaviyoListId: {
    //     title: "List ID",
    //     type: ControlType.String,
    //     defaultValue: "",
    //     description: "List ID from Klaviyo",
    //     hidden: (props) =>
    //         notSubmitForm(props) || props.submitPlatform != "klaviyo",
    // },
    // advancedSubmitConfig: {
    //     type: ControlType.Object,
    //     optional: true,
    //     title: "Advanced",
    //     buttonTitle: "Options",
    //     controls: {
    //         contentType: {
    //             type: ControlType.Enum,
    //             defaultValue: "application/json",
    //             options: [
    //                 "application/json",
    //                 "application/x-www-form-urlencoded",
    //             ],
    //             title: "Content-Type",
    //         },
    //         fetchMode: {
    //             type: ControlType.Enum,
    //             defaultValue: "no-cors",
    //             options: ["cors", "no-cors"],
    //             displaySegmentedControl: true,
    //         },
    //     },
    //     hidden: (props) =>
    //         notSubmitForm(props) || props.submitPlatform != "url",
    // },
    apiConfig: {
        type: ControlType.Object,
        optional: true,
        title: "API Config",
        buttonTitle: "API Key",
        controls: {
            apiKey: {
                type: ControlType.String,
                title: "API Key",
            },
        },
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform != "url",
    },
    ///////////////////////////////////////////////////////////////////////
    responseInfo: {
        type: ControlType.Object,
        buttonTitle: "Options",
        controls: {
            utmParams: {
                type: ControlType.Boolean,
                defaultValue: false,
                description: "Include UTM parameters in the form response.",
                title: "UTM Params",
            },
            url: {
                type: ControlType.Boolean,
                defaultValue: false,
                description: "Include the page URL in the form response.",
                title: "URL",
            },
            urlName: {
                type: ControlType.String,
                defaultValue: "url",
                description: "Form response field name.",
                title: "URL Name",
                hidden: (props) => !props.url,
            },
        },
        hidden: (props) =>
            notSubmitForm(props) || props.submitPlatform == "hubSpot",
    },
    redirectOnSuccess: {
        type: ControlType.Link,
        hidden: notSubmitForm,
    },
    newTab: {
        type: ControlType.Boolean,
        defaultValue: false,
        hidden: notSubmitForm,
    },
    scrollToInvalidField: {
        type: ControlType.Object,
        defaultValue: { offset: -24 },
        optional: true,
        description: " ",
        controls: {
            offset: {
                type: ControlType.Number,
                defaultValue: -24,
                step: 1,
            },
        },
        hidden: notSubmitForm,
    },
    fill: fillProp({
        color: "#0075FF",
        colorA: "#70B3FF",
        colorB: "#0075FF",
    }),
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#FFF",
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: 1,
        },
    },
    text: {
        type: ControlType.String,
        defaultValue: "Submit",
    },
    border: {
        type: ControlType.Object,
        optional: true,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#222",
            },
            width: {
                type: ControlType.FusedNumber,
                defaultValue: 1,
                toggleKey: "widthIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "widthTop",
                    "widthRight",
                    "widthBottom",
                    "widthLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 1,
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
    },
    shadows: {
        type: ControlType.BoxShadow,
    },
    icon: iconPropertyControls,
    padding: {
        type: ControlType.FusedNumber,
        defaultValue: 16,
        toggleKey: "paddingIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 8,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "radiusTopLeft",
            "radiusTopRight",
            "radiusBottomRight",
            "radiusBottomLeft",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    hoverCustomization: {
        type: ControlType.Object,
        optional: true,
        buttonTitle: "Colors",
        title: "Hover",
        icon: "effect",
        controls: {
            fill: fillProp({ noDefault: true }),
            fontColor: {
                type: ControlType.Color,
                optional: true,
            },
            borderColor: {
                type: ControlType.Color,
                optional: true,
            },
        },
    },
    pressCustomization: {
        type: ControlType.Object,
        optional: true,
        buttonTitle: "Colors",
        title: "Press",
        icon: "effect",
        controls: {
            fill: fillProp({ noDefault: true }),
            fontColor: {
                type: ControlType.Color,
                optional: true,
            },
            borderColor: {
                type: ControlType.Color,
                optional: true,
            },
        },
    },
    transition: {
        type: ControlType.Transition,
    },
    success: {
        type: ControlType.EventHandler,
    },
    failure: {
        type: ControlType.EventHandler,
    },
})

function notSubmitForm(props) {
    return props.clickAction != "submitForm"
}

const parseMailchimpUrl = (url) => {
    const matchResult = url
        .replace(/&amp;/g, "&")
        .match(/^https?:\/\/([^\/]+)[^\?]+\??(.+)$/)

    // Check if there was no match
    if (!matchResult) {
        return [null, null]
    }

    // Extract domain and parameters from the match result
    const [, domain, parameters] = matchResult

    // Convert parameters to an object if they exist, otherwise null
    const parametersObject = parameters
        ? Object.fromEntries(new URLSearchParams(parameters))
        : null

    return [domain, parametersObject]
}

const MAILCHIMP_REPLACE = {
    firstName: "FNAME",
    lastName: "LNAME",
    addressLine1: "ADDRESS[addr1]",
    addressLine2: "ADDRESS[addr2]",
    addressCity: "ADDRESS[city]",
    addressState: "ADDRESS[state]",
    addressZip: "ADDRESS[zip]",
    addressCountry: "ADDRESS[country]",
}

const KLAVIYO_FIELDS = [
    "email",
    "phone_number",
    "first_name",
    "last_name",
    "title",
    "organization",
    "city",
    "region",
    "country",
    "zip",
    "consent",
]

function processFieldName(name, submitPlatform) {
    switch (submitPlatform) {
        case "mailchimp":
            return MAILCHIMP_REPLACE[name] || name
        case "mailerLite":
            return `fields[${name}]`
        case "klaviyo":
            return KLAVIYO_FIELDS.includes(name) ? `$${name}` : name
        default:
            return name
    }
}

function getCookieValue(cookieName) {
    const name = cookieName + "="
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}
